
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    modelValue: Number
  }
})
export default class Scale extends Vue {
  modelValue!: 5

  onInput (event: InputEvent): void {
    this.$emit(
      'update:modelValue',
      (event?.target as HTMLTextAreaElement).value
    )
  }

  onChange (event: InputEvent): void {
    this.$emit('change', (event?.target as HTMLTextAreaElement).value)
  }

  goToScale (): void {
    this.$emit('scale')
  }
}
