import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-row justify-between" }
const _hoisted_5 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_6 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_7 = { class: "flex flex-row justify-between" }
const _hoisted_8 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_9 = { class: "space-y-6 sm:space-y-5" }
const _hoisted_10 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_11 = {
  for: "id",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_12 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_13 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_14 = {
  for: "age",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_15 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_16 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_17 = {
  for: "sex",
  class: "block text-sm font-medium text-gray-700"
}
const _hoisted_18 = ["selected"]
const _hoisted_19 = ["selected"]
const _hoisted_20 = ["selected"]
const _hoisted_21 = ["selected"]
const _hoisted_22 = {
  key: 0,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_23 = {
  for: "pregnant",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_24 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_25 = {
  key: 0,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_26 = {
  for: "breast_feeding",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_27 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_28 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_29 = {
  for: "weight",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_30 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_31 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_32 = {
  for: "systolic_blood_pressure",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_33 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_34 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_35 = {
  for: "diastolic_blood_pressure",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_36 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_37 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_38 = {
  for: "frequencia-cardiaca",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_39 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_40 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_41 = {
  for: "accelerometer",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_42 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_43 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_44 = {
  for: "education_level",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_45 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_46 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_47 = { value: "0" }
const _hoisted_48 = { value: "1" }
const _hoisted_49 = { value: "2" }
const _hoisted_50 = { value: "3" }
const _hoisted_51 = { value: "4" }
const _hoisted_52 = { value: "5" }
const _hoisted_53 = { value: "6" }
const _hoisted_54 = {
  key: 0,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_55 = {
  for: "education_level_parent1",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_56 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_57 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_58 = { value: "0" }
const _hoisted_59 = { value: "1" }
const _hoisted_60 = { value: "2" }
const _hoisted_61 = { value: "3" }
const _hoisted_62 = { value: "4" }
const _hoisted_63 = { value: "5" }
const _hoisted_64 = { value: "6" }
const _hoisted_65 = {
  key: 1,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_66 = {
  for: "education_level_parent2",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_67 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_68 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_69 = { value: "0" }
const _hoisted_70 = { value: "1" }
const _hoisted_71 = { value: "2" }
const _hoisted_72 = { value: "3" }
const _hoisted_73 = { value: "4" }
const _hoisted_74 = { value: "5" }
const _hoisted_75 = { value: "6" }
const _hoisted_76 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_77 = {
  for: "work_status",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_78 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_79 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_80 = { value: "0" }
const _hoisted_81 = { value: "1" }
const _hoisted_82 = { value: "2" }
const _hoisted_83 = { value: "3" }
const _hoisted_84 = {
  key: 2,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_85 = {
  for: "work_status_parent1",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_86 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_87 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_88 = { value: "0" }
const _hoisted_89 = { value: "1" }
const _hoisted_90 = { value: "2" }
const _hoisted_91 = { value: "3" }
const _hoisted_92 = {
  key: 3,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_93 = {
  for: "work_status_parent2",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_94 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_95 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_96 = { value: "0" }
const _hoisted_97 = { value: "1" }
const _hoisted_98 = { value: "2" }
const _hoisted_99 = { value: "3" }
const _hoisted_100 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_101 = {
  for: "adhd_inattentive",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_102 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_103 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_104 = {
  for: "adhd_combined",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_105 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_106 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_107 = {
  for: "adhd_hyperactive",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_108 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_109 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_110 = {
  for: "adhd_severity",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_111 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_112 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_113 = {
  for: "cgi_s",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_114 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_115 = {
  selected: "",
  disabled: "",
  hidden: "",
  value: "null"
}
const _hoisted_116 = { value: "1" }
const _hoisted_117 = { value: "2" }
const _hoisted_118 = { value: "3" }
const _hoisted_119 = { value: "4" }
const _hoisted_120 = { value: "5" }
const _hoisted_121 = { value: "6" }
const _hoisted_122 = { value: "7" }
const _hoisted_123 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_124 = {
  for: "adequate_intellectual_functioning",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_125 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_126 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_127 = {
  for: "wisc_asked",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_128 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_129 = {
  key: 0,
  class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
}
const _hoisted_130 = {
  for: "wisc_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_131 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_132 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_133 = {
  for: "soc_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_134 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_135 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_136 = {
  for: "ae_puntuation",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_137 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_138 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_139 = {
  for: "alfb_adhd_puntuation_correct",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_140 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_141 = { class: "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5" }
const _hoisted_142 = {
  for: "alfb_adhd_puntuation_incorrect",
  class: "block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
}
const _hoisted_143 = { class: "mt-1 sm:mt-0 sm:col-span-2" }
const _hoisted_144 = { class: "pt-5" }
const _hoisted_145 = { class: "flex justify-end" }
const _hoisted_146 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yesNoRadio = _resolveComponent("yesNoRadio")!
  const _component_ScaleButton = _resolveComponent("ScaleButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[32] || (_cache[32] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('PatientData.Title')), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('PatientData.VisitDate')), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('PatientData.PersonalPatientInfo')), 1),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.basic_info.visited) = $event)),
                type: "date",
                name: "visited",
                id: "visited",
                required: "",
                class: "max-w-lg block shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              }, null, 512), [
                [_vModelText, _ctx.basic_info.visited]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('PatientData.PatientId')), 1),
              _createElementVNode("div", _hoisted_12, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.basic_info.alias) = $event)),
                  type: "text",
                  name: "id",
                  id: "id",
                  autocomplete: "given-name",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.basic_info.alias]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('PatientData.age')), 1),
              _createElementVNode("div", _hoisted_15, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.basic_info.age) = $event)),
                  type: "number",
                  name: "age",
                  id: "age",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.basic_info.age]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('PatientData.sex')), 1),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.basic_info.sex) = $event)),
                onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.changingSex())),
                id: "sex",
                name: "sex",
                class: "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              }, [
                _createElementVNode("option", {
                  value: "-1",
                  disabled: "",
                  selected: _ctx.basic_info.sex === -1
                }, _toDisplayString(_ctx.$t('PatientData.ChooseAnOption')), 9, _hoisted_18),
                _createElementVNode("option", {
                  value: "1",
                  selected: _ctx.basic_info.sex === 1
                }, _toDisplayString(_ctx.$t('PatientData.SexMale')), 9, _hoisted_19),
                _createElementVNode("option", {
                  value: "2",
                  selected: _ctx.basic_info.sex === 2
                }, _toDisplayString(_ctx.$t('PatientData.SexFemale')), 9, _hoisted_20),
                _createElementVNode("option", {
                  value: "0",
                  selected: _ctx.basic_info.sex === 0
                }, _toDisplayString(_ctx.$t('PatientData.SexOthers')), 9, _hoisted_21)
              ], 544), [
                [_vModelSelect, _ctx.basic_info.sex]
              ])
            ]),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__fadeIn",
              "leave-active-class": "animate__animated animate__fadeOut"
            }, {
              default: _withCtx(() => [
                (_ctx.renderPreg)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('PatientData.pregnant')), 1),
                      _createElementVNode("div", _hoisted_24, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.basic_info.pregnant,
                          onYesnoevent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.basic_info.pregnant = $event)),
                          key: _ctx.basic_info.pregnant
                        }, null, 8, ["value"]))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__fadeIn",
              "leave-active-class": "animate__animated animate__fadeOut"
            }, {
              default: _withCtx(() => [
                (_ctx.renderPreg)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('PatientData.breast_feeding')), 1),
                      _createElementVNode("div", _hoisted_27, [
                        (_openBlock(), _createBlock(_component_yesNoRadio, {
                          value: _ctx.basic_info.breast_feeding,
                          onYesnoevent: _cache[6] || (_cache[6] = ($event: any) => (_ctx.basic_info.breast_feeding = $event)),
                          key: _ctx.basic_info.breast_feeding
                        }, null, 8, ["value"]))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('PatientData.weight')), 1),
              _createElementVNode("div", _hoisted_30, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.basic_info.weight) = $event)),
                  type: "number",
                  step: "0.1",
                  name: "weight",
                  id: "weight",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.basic_info.weight]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('PatientData.systolic_blood_pressure')), 1),
              _createElementVNode("div", _hoisted_33, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.patient_information.systolic_blood_pressure) = $event)),
                  type: "number",
                  name: "systolic_blood_pressure",
                  id: "systolic_blood_pressure",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.systolic_blood_pressure]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('PatientData.diastolic_blood_pressure')), 1),
              _createElementVNode("div", _hoisted_36, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.patient_information.diastolic_blood_pressure) = $event)),
                  type: "number",
                  name: "diastolic_blood_pressure",
                  id: "diastolic_blood_pressure",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.diastolic_blood_pressure]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('PatientData.heart_rate')), 1),
              _createElementVNode("div", _hoisted_39, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.patient_information.heart_rate) = $event)),
                  type: "number",
                  name: "frequencia-cardiaca",
                  id: "frequencia-cardiaca",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.heart_rate]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("label", _hoisted_41, _toDisplayString(_ctx.$t('PatientData.accelerometer')), 1),
              _createElementVNode("div", _hoisted_42, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.accelerometer,
                  onYesnoevent: _cache[11] || (_cache[11] = ($event: any) => (_ctx.patient_information.accelerometer = $event)),
                  key: _ctx.patient_information.accelerometer
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('PatientData.education_level')), 1),
              _createElementVNode("div", _hoisted_45, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.patient_information.education_level) = $event)),
                  name: "education_level",
                  id: "education_level",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, [
                  _createElementVNode("option", _hoisted_46, _toDisplayString(_ctx.$t('PatientData.select_education_level')), 1),
                  _createElementVNode("option", _hoisted_47, _toDisplayString(_ctx.$t('PatientData.education_level-childhood')), 1),
                  _createElementVNode("option", _hoisted_48, _toDisplayString(_ctx.$t('PatientData.education_level-primary')), 1),
                  _createElementVNode("option", _hoisted_49, _toDisplayString(_ctx.$t('PatientData.education_level-secondary')), 1),
                  _createElementVNode("option", _hoisted_50, _toDisplayString(_ctx.$t('PatientData.education_level-bachelor')), 1),
                  _createElementVNode("option", _hoisted_51, _toDisplayString(_ctx.$t('PatientData.education_level-vocational')), 1),
                  _createElementVNode("option", _hoisted_52, _toDisplayString(_ctx.$t('PatientData.education_level-university')), 1),
                  _createElementVNode("option", _hoisted_53, _toDisplayString(_ctx.$t('PatientData.education_level-special')), 1)
                ], 512), [
                  [_vModelSelect, _ctx.patient_information.education_level]
                ])
              ])
            ]),
            (_ctx.lifeStage != 'Adult')
              ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                  _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t('PatientData.education_level_parent1')), 1),
                  _createElementVNode("div", _hoisted_56, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.patient_information.education_level_parent1) = $event)),
                      name: "education_level_parent1",
                      id: "education_level_parent1",
                      class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    }, [
                      _createElementVNode("option", _hoisted_57, _toDisplayString(_ctx.$t('PatientData.select_education_level')), 1),
                      _createElementVNode("option", _hoisted_58, _toDisplayString(_ctx.$t('PatientData.education_level-childhood')), 1),
                      _createElementVNode("option", _hoisted_59, _toDisplayString(_ctx.$t('PatientData.education_level-primary')), 1),
                      _createElementVNode("option", _hoisted_60, _toDisplayString(_ctx.$t('PatientData.education_level-secondary')), 1),
                      _createElementVNode("option", _hoisted_61, _toDisplayString(_ctx.$t('PatientData.education_level-bachelor')), 1),
                      _createElementVNode("option", _hoisted_62, _toDisplayString(_ctx.$t('PatientData.education_level-vocational')), 1),
                      _createElementVNode("option", _hoisted_63, _toDisplayString(_ctx.$t('PatientData.education_level-university')), 1),
                      _createElementVNode("option", _hoisted_64, _toDisplayString(_ctx.$t('PatientData.education_level-special')), 1)
                    ], 512), [
                      [_vModelSelect, _ctx.patient_information.education_level_parent1]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.lifeStage != 'Adult')
              ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                  _createElementVNode("label", _hoisted_66, _toDisplayString(_ctx.$t('PatientData.education_level_parent2')), 1),
                  _createElementVNode("div", _hoisted_67, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.patient_information.education_level_parent2) = $event)),
                      name: "education_level_parent2",
                      id: "education_level_parent2",
                      class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    }, [
                      _createElementVNode("option", _hoisted_68, _toDisplayString(_ctx.$t('PatientData.select_education_level')), 1),
                      _createElementVNode("option", _hoisted_69, _toDisplayString(_ctx.$t('PatientData.education_level-childhood')), 1),
                      _createElementVNode("option", _hoisted_70, _toDisplayString(_ctx.$t('PatientData.education_level-primary')), 1),
                      _createElementVNode("option", _hoisted_71, _toDisplayString(_ctx.$t('PatientData.education_level-secondary')), 1),
                      _createElementVNode("option", _hoisted_72, _toDisplayString(_ctx.$t('PatientData.education_level-bachelor')), 1),
                      _createElementVNode("option", _hoisted_73, _toDisplayString(_ctx.$t('PatientData.education_level-vocational')), 1),
                      _createElementVNode("option", _hoisted_74, _toDisplayString(_ctx.$t('PatientData.education_level-university')), 1),
                      _createElementVNode("option", _hoisted_75, _toDisplayString(_ctx.$t('PatientData.education_level-special')), 1)
                    ], 512), [
                      [_vModelSelect, _ctx.patient_information.education_level_parent2]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_76, [
              _createElementVNode("label", _hoisted_77, _toDisplayString(_ctx.$t('PatientData.work_status')), 1),
              _createElementVNode("div", _hoisted_78, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.patient_information.work_status) = $event)),
                  name: "work_status",
                  id: "work_status",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, [
                  _createElementVNode("option", _hoisted_79, _toDisplayString(_ctx.$t('PatientData.select_work_status')), 1),
                  _createElementVNode("option", _hoisted_80, _toDisplayString(_ctx.$t('PatientData.work_status-studying')), 1),
                  _createElementVNode("option", _hoisted_81, _toDisplayString(_ctx.$t('PatientData.work_status-full_time')), 1),
                  _createElementVNode("option", _hoisted_82, _toDisplayString(_ctx.$t('PatientData.work_status-part_time')), 1),
                  _createElementVNode("option", _hoisted_83, _toDisplayString(_ctx.$t('PatientData.work_status-unemployed')), 1)
                ], 512), [
                  [_vModelSelect, _ctx.patient_information.work_status]
                ])
              ])
            ]),
            (_ctx.lifeStage != 'Adult')
              ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                  _createElementVNode("label", _hoisted_85, _toDisplayString(_ctx.$t('PatientData.work_status_parent1')), 1),
                  _createElementVNode("div", _hoisted_86, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.patient_information.work_status_parent1) = $event)),
                      name: "work_status_parent1",
                      id: "work_status_parent1",
                      class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    }, [
                      _createElementVNode("option", _hoisted_87, _toDisplayString(_ctx.$t('PatientData.select_work_status')), 1),
                      _createElementVNode("option", _hoisted_88, _toDisplayString(_ctx.$t('PatientData.work_status-studying')), 1),
                      _createElementVNode("option", _hoisted_89, _toDisplayString(_ctx.$t('PatientData.work_status-full_time')), 1),
                      _createElementVNode("option", _hoisted_90, _toDisplayString(_ctx.$t('PatientData.work_status-part_time')), 1),
                      _createElementVNode("option", _hoisted_91, _toDisplayString(_ctx.$t('PatientData.work_status-unemployed')), 1)
                    ], 512), [
                      [_vModelSelect, _ctx.patient_information.work_status_parent1]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.lifeStage != 'Adult')
              ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                  _createElementVNode("label", _hoisted_93, _toDisplayString(_ctx.$t('PatientData.work_status_parent2')), 1),
                  _createElementVNode("div", _hoisted_94, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.patient_information.work_status_parent2) = $event)),
                      name: "work_status_parent2",
                      id: "work_status_parent2",
                      class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    }, [
                      _createElementVNode("option", _hoisted_95, _toDisplayString(_ctx.$t('PatientData.select_work_status')), 1),
                      _createElementVNode("option", _hoisted_96, _toDisplayString(_ctx.$t('PatientData.work_status-studying')), 1),
                      _createElementVNode("option", _hoisted_97, _toDisplayString(_ctx.$t('PatientData.work_status-full_time')), 1),
                      _createElementVNode("option", _hoisted_98, _toDisplayString(_ctx.$t('PatientData.work_status-part_time')), 1),
                      _createElementVNode("option", _hoisted_99, _toDisplayString(_ctx.$t('PatientData.work_status-unemployed')), 1)
                    ], 512), [
                      [_vModelSelect, _ctx.patient_information.work_status_parent2]
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_100, [
              _createElementVNode("label", _hoisted_101, _toDisplayString(_ctx.$t('PatientData.adhd_inattentive')), 1),
              _createElementVNode("div", _hoisted_102, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.adhd_inattentive,
                  onYesnoevent: _cache[18] || (_cache[18] = ($event: any) => (_ctx.patient_information.adhd_inattentive = $event)),
                  key: _ctx.patient_information.adhd_inattentive
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_103, [
              _createElementVNode("label", _hoisted_104, _toDisplayString(_ctx.$t('PatientData.adhd_combined')), 1),
              _createElementVNode("div", _hoisted_105, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.adhd_combined,
                  onYesnoevent: _cache[19] || (_cache[19] = ($event: any) => (_ctx.patient_information.adhd_combined = $event)),
                  key: _ctx.patient_information.adhd_combined
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_106, [
              _createElementVNode("label", _hoisted_107, _toDisplayString(_ctx.$t('PatientData.adhd_hyperactive')), 1),
              _createElementVNode("div", _hoisted_108, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.adhd_hyperactive,
                  onYesnoevent: _cache[20] || (_cache[20] = ($event: any) => (_ctx.patient_information.adhd_hyperactive = $event)),
                  key: _ctx.patient_information.adhd_hyperactive
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_109, [
              _createElementVNode("label", _hoisted_110, _toDisplayString(_ctx.$t('PatientData.adhd_severity')), 1),
              _createElementVNode("div", _hoisted_111, [
                _createVNode(_component_ScaleButton, {
                  modelValue: _ctx.patient_information.adhd_severity,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.patient_information.adhd_severity) = $event)),
                  onScale: _ctx.goToAdhdSeverityScale
                }, null, 8, ["modelValue", "onScale"])
              ])
            ]),
            _createElementVNode("div", _hoisted_112, [
              _createElementVNode("label", _hoisted_113, _toDisplayString(_ctx.$t('PatientData.cgi_s')), 1),
              _createElementVNode("div", _hoisted_114, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.patient_information.cgi_s) = $event)),
                  name: "cgi_s",
                  id: "cgi_s",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, [
                  _createElementVNode("option", _hoisted_115, _toDisplayString(_ctx.$t('PatientData.cgi_s_select')), 1),
                  _createElementVNode("option", _hoisted_116, " 1.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_normal')), 1),
                  _createElementVNode("option", _hoisted_117, " 2.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_borderline')), 1),
                  _createElementVNode("option", _hoisted_118, " 3.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_mildly')), 1),
                  _createElementVNode("option", _hoisted_119, " 4.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_moderately')), 1),
                  _createElementVNode("option", _hoisted_120, " 5.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_markedly')), 1),
                  _createElementVNode("option", _hoisted_121, " 6.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_severely')), 1),
                  _createElementVNode("option", _hoisted_122, " 7.- " + _toDisplayString(_ctx.$t('PatientData.cgi_s_extremely')), 1)
                ], 512), [
                  [_vModelSelect, _ctx.patient_information.cgi_s]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_123, [
              _createElementVNode("label", _hoisted_124, _toDisplayString(_ctx.$t('PatientData.adequate_intellectual_functioning')), 1),
              _createElementVNode("div", _hoisted_125, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.adequate_intellectual_functioning,
                  onYesnoevent: _cache[23] || (_cache[23] = ($event: any) => (
                    _ctx.patient_information.adequate_intellectual_functioning =
                      $event
                  )),
                  key: _ctx.patient_information.adequate_intellectual_functioning
                }, null, 8, ["value"]))
              ])
            ]),
            _createElementVNode("div", _hoisted_126, [
              _createElementVNode("label", _hoisted_127, _toDisplayString(_ctx.$t('PatientData.wisc_asked')), 1),
              _createElementVNode("div", _hoisted_128, [
                (_openBlock(), _createBlock(_component_yesNoRadio, {
                  value: _ctx.patient_information.wisc_asked,
                  onYesnoevent: _cache[24] || (_cache[24] = ($event: any) => (_ctx.patient_information.wisc_asked = $event)),
                  onChange: _cache[25] || (_cache[25] = ($event: any) => (_ctx.wiscChanged())),
                  key: _ctx.patient_information.wisc_asked
                }, null, 8, ["value"]))
              ])
            ]),
            _createVNode(_Transition, {
              "enter-active-class": "animate__animated animate__fadeIn",
              "leave-active-class": "animate__animated animate__fadeOut"
            }, {
              default: _withCtx(() => [
                (_ctx.renderWISC)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_129, [
                      _createElementVNode("label", _hoisted_130, _toDisplayString(_ctx.$t('PatientData.wisc_puntuation')), 1),
                      _createElementVNode("div", _hoisted_131, [
                        _withDirectives(_createElementVNode("textarea", {
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.patient.patient_information.wisc_puntuation) = $event)),
                          id: "wisc_puntuation",
                          name: "wisc_puntuation",
                          rows: "3",
                          class: "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                        }, null, 512), [
                          [_vModelText, _ctx.patient.patient_information.wisc_puntuation]
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_132, [
              _createElementVNode("label", _hoisted_133, _toDisplayString(_ctx.$t('PatientData.soc_puntuation')), 1),
              _createElementVNode("div", _hoisted_134, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.patient_information.soc_puntuation) = $event)),
                  type: "number",
                  step: "1",
                  name: "soc_puntuation",
                  id: "soc_puntuation",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.soc_puntuation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_135, [
              _createElementVNode("label", _hoisted_136, _toDisplayString(_ctx.$t('PatientData.ae_puntuation')), 1),
              _createElementVNode("div", _hoisted_137, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.patient_information.ae_puntuation) = $event)),
                  type: "number",
                  step: "1",
                  name: "ae_puntuation",
                  id: "ae_puntuation",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.ae_puntuation]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_138, [
              _createElementVNode("label", _hoisted_139, _toDisplayString(_ctx.$t('PatientData.alfb_adhd_puntuation_correct')), 1),
              _createElementVNode("div", _hoisted_140, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.patient_information.alfb_adhd_puntuation_correct) = $event)),
                  type: "number",
                  step: "1",
                  name: "alfb_adhd_puntuation_correct",
                  id: "alfb_adhd_puntuation_correct",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.alfb_adhd_puntuation_correct]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_141, [
              _createElementVNode("label", _hoisted_142, _toDisplayString(_ctx.$t('PatientData.alfb_adhd_puntuation_incorrect')), 1),
              _createElementVNode("div", _hoisted_143, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.patient_information.alfb_adhd_puntuation_incorrect) = $event)),
                  type: "number",
                  step: "1",
                  name: "alfb_adhd_puntuation_incorrect",
                  id: "alfb_adhd_puntuation_incorrect",
                  class: "max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                }, null, 512), [
                  [_vModelText, _ctx.patient_information.alfb_adhd_puntuation_incorrect]
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_144, [
        _createElementVNode("div", _hoisted_145, [
          _createElementVNode("button", {
            onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('PatientData.Back')), 1),
          _createElementVNode("button", _hoisted_146, _toDisplayString(_ctx.$t('PatientData.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}