
import Patient from '@/models/Patient'
import Swal from 'sweetalert2'
import YesNoRadio from '@/components/YesNoRadio.vue'
import ScaleButton from '@/components/ScaleButton.vue'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import LifeStage from '@/enums/LifeStage'

@Options({
  components: {
    YesNoRadio,
    ScaleButton
  },
  methods: mapActions([
    'updateDataAndInfo',
    'downloadPatientDataAndInfo',
    'createPatient'
  ]),
  computed: mapGetters([
    'patient',
    'basic_info',
    'patient_information',
    'lifeStage'
  ])
})
export default class PatientData extends Vue {
  patient!: Patient
  // eslint-disable-next-line camelcase
  basic_info!: Patient['basic_info']
  // eslint-disable-next-line camelcase
  patient_information!: Patient['patient_information']
  lifeStage!: LifeStage
  renderPreg = false
  renderWISC = false

  // Actions
  updateDataAndInfo!: (data: Patient) => Promise<void>
  downloadPatientDataAndInfo!: (alias: string) => Promise<void>
  createPatient!: (alias: string) => Promise<void>

  async created (): Promise<void> {
    try {
      await this.downloadPatientDataAndInfo(String(this.$route.params.alias))

      this.renderPreg = this.basic_info.sex === 2
      this.renderWISC = this.patient_information.wisc_asked
    } catch (err: any) {
      const message = err?.response?.data?.message
      if (message === 'Patient not found.') {
        const result = await Swal.fire({
          icon: 'info',
          title: this.$t('PatientData.DownloadFailedTitle'),
          confirmButtonText: this.$t('PatientData.DownloadFailedConfirmButton'),
          showCancelButton: true,
          cancelButtonText: this.$t('PatientData.DownloadFailedCancelButton')
        })

        if (result.isConfirmed) {
          this.createPatient(String(this.$route.params.alias))
        } else {
          this.$router.push({ name: 'Dashboard' })
        }
      }
    }
  }

  changingSex (): void {
    if (Number(this.basic_info.sex) !== 2) {
      this.basic_info.pregnant = false
      this.basic_info.breast_feeding = false
    }

    this.renderPreg = Number(this.basic_info.sex) === 2
  }

  wiscChanged (): void {
    this.renderWISC = this.patient_information.wisc_asked
  }

  async submit (): Promise<void> {
    this.$router.replace({
      name: 'PatientData',
      params: { alias: this.basic_info.alias }
    })

    try {
      await this.updateDataAndInfo(this.patient)
      this.$router.push({
        name: 'Comorbidity',
        params: { id: this.patient.id }
      })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }

  async goToAdhdSeverityScale (): Promise<void> {
    await this.updateDataAndInfo(this.patient)

    this.$router.push({
      name: 'AdHdSeverityScale',
      params: { alias: this.basic_info.alias }
    })
  }
}
